/** 
  * Fonts css main file
*/

@font-face {
  font-family: 'Gotham-Black';
  src: url('./Gotham-Black.eot');
  src: url('./Gotham-Black.eot?#iefix') format('embedded-opentype'),
    url('./Gotham-Black.woff') format('woff'), url('./Gotham-Black.ttf') format('truetype'),
    url('./Gotham-Black.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'GothamBold';
  src: url('./GothamBold.eot');
  src: url('./GothamBold.eot?#iefix') format('embedded-opentype'),
    url('./GothamBold.woff') format('woff'), url('./GothamBold.ttf') format('truetype'),
    url('./GothamBold.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'GothamCondensed-Medium';
  src: url('./GothamCondensed-Medium.eot');
  src: url('./GothamCondensed-Medium.eot?#iefix') format('embedded-opentype'),
    url('./GothamCondensed-Medium.woff') format('woff'),
    url('./GothamCondensed-Medium.ttf') format('truetype'),
    url('./GothamCondensed-Medium.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'Gotham-Light';
  src: url('./Gotham-Light.eot');
  src: url('./Gotham-Light.eot?#iefix') format('embedded-opentype'),
    url('./Gotham-Light.woff') format('woff'), url('./Gotham-Light.ttf') format('truetype'),
    url('./Gotham-Light.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'Gotham-Medium';
  src: url('./Gotham-Medium.eot');
  src: url('./Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('./Gotham-Medium.woff2') format('woff2'), url('./Gotham-Medium.woff') format('woff'),
    url('./Gotham-Medium.ttf') format('truetype'),
    url('./Gotham-Medium.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'NarkisBlockMF-Bold';
  src: url('./NarkisBlockMF-Bold.eot');
  src: url('./NarkisBlockMF-Bold.eot?#iefix') format('embedded-opentype'),
    url('./NarkisBlockMF-Bold.woff2') format('woff2'),
    url('./NarkisBlockMF-Bold.woff') format('woff'),
    url('./NarkisBlockMF-Bold.ttf') format('truetype'),
    url('./NarkisBlockMF-Bold.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'NarkisBlockMF-Thin';
  src: url('./NarkisBlockMF-Thin.eot');
  src: url('./NarkisBlockMF-Thin.eot?#iefix') format('embedded-opentype'),
    url('./NarkisBlockMF-Thin.woff2') format('woff2'),
    url('./NarkisBlockMF-Thin.woff') format('woff'),
    url('./NarkisBlockMF-Thin.ttf') format('truetype'),
    url('./NarkisBlockMF-Thin.svg#svgFontName') format('svg');
}

html,
body,
p {
  font-family: 'NarkisBlockMF-Thin';
}

::placeholder {
  font-family: 'NarkisBlockMF-Thin';
  color: #fff;
  font-size: 0.8vw;
}
.GothamBold {
  font-family: 'GothamBold';
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-family: 'NarkisBlockMF-Bold';
  font-weight: bold;
}

.font-narkis-bold {
  font-family: 'NarkisBlockMF-Bold';
}
.font-narkis-thin {
  font-family: 'NarkisBlockMF-Thin';
}
.ac-en-font-bold,
.ac-en-font-bold > * {
  font-family: 'GothamBold';
}
.ac-en-font-light,
.ac-en-font-light > * {
  font-family: 'Gotham-Light';
}
.ac-en-font-medium {
  font-family: 'Gotham-Medium';
}

@media all and (max-width: 1000px) {
  ::placeholder {
    font-size: 3vw;
  }
}
